<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-row class="text-center">
            <b-col>
              For get detail explaination, Please click the "{{ $t("Export Template") }}" button and check the template file
            </b-col>
          </b-row>
          <b-card>
            <p class="p-3" v-if="import_exportlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="import_exportlist"
              >
                <el-table-column :label="$t('Import')">
                  <template v-slot="{ row }">
                    <b-button
                      variant="outline-dark"
                      size="sm"
                      @click="do_import(row)"
                      >{{ $t("Import") + " " + $t(row.Name) }}</b-button
                    >
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Export')">
                  <template v-slot="{ row }">
                    <b-button
                      variant="outline-dark"
                      size="sm"
                      @click="do_export_data(row.Name)"
                      >{{ $t("Export") + " " + $t(row.Name) }}</b-button
                    >
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Export Template')">
                  <template v-slot="{ row }">
                    <b-button
                      variant="outline-dark"
                      size="sm"
                      @click="do_export_temp(row.Name)"
                      >{{
                        $t("Export") + " " + $t(row.Name) + " " + $t("Template")
                      }}</b-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'import'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row class="justify-content-lg-center">
                  <b-col class="text-center">
                    <h2>{{ $t(cur_import.Name) }}</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('File')">
                      <b-form-file
                        v-model="file1"
                        :state="Boolean(file1)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      ></b-form-file>
                    </base-input>
                  </b-col>
                  <b-col class="text-center" lg="4" v-if="cur_import.Name != 'Combo'">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="clean"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                      class="form-checkbox"
                    >
                      {{ $t("Remove Old Data Before Import") }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button
                      type="primary"
                      class="submitbutton"
                      @click="form_submit"
                      >{{ $t("Submit") }}</el-button
                    >
                    <el-button
                      type="primary"
                      class="submitbutton"
                      @click="form_cancel"
                      >{{ $t("Back") }}</el-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
      <section>
        <xlsx-workbook @change="change_workbook">
          <xlsx-sheet
            :collection="sheet_data"
            :key="sheet_name"
            :sheet-name="sheet_name"
          />
          <xlsx-download :filename="sheet_name + '.xlsx'">
            <button ref="download_btn" style="display: none;">Download</button>
          </xlsx-download>
        </xlsx-workbook>
      </section>
      <vue-element-loading
        :active="showloading"
        spinner="bar-fade-scale"
        color="#FF6700"
        duration="2.0"
        background-color="rgba(255, 255, 255, .5)"
        size="100"
      />
      </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import { getStore } from "../../util/helper";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    VueElementLoading,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      currpage: "list",
      cur_opt: null,
      cur_import: null,
      import_exportlist: null,

      showloading: false,

      file1: null,

      sheet_data: [{a: 1, b: 2, c: 3},{a: 1, d: 2, c: 3},],
      sheet_name: "test",

      temp_table_data: [],
      temp_table_name: "TableTemplate",
      temp_modifier_data: [],
      temp_modifier_name: "ModifierTemplate",
      temp_product_data: [],
      temp_product_name: "ProductTemplate",
      temp_combo_data: [],
      temp_combo_name: "ComboTemplate",
      temp_customer_data: [],
      temp_customer_name: "CustomerTemplate",
      temp_inventory_data: [],
      temp_inventory_name: "InventoryTemplate",

      sheet_table_data: [],
      sheet_table_name: "Table",
      sheet_modifier_data: [],
      sheet_modifier_name: "Modifier",
      sheet_product_data: [],
      sheet_product_name: "Product",
      sheet_combo_data: [],
      sheet_combo_name: "Combo",
      sheet_customer_data: [],
      sheet_customer_name: "Customer",
      sheet_inventory_data: [],
      sheet_inventory_name: "Inventory",

      clean: 0,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    testfunc() {
    },
    change_workbook(wb) {
      if (wb.SheetNames && wb.SheetNames.length > 0) {
        this.do_real_download();
      }
    },
    do_real_download() {
      if (this.$refs && (this.$refs.download_btn != undefined)) {
        this.$refs.download_btn.click();
      }
    },
    do_export_temp(tname) {
      if (tname == "Table") {
        if (this.sheet_name == this.temp_table_name) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_table_data;
          this.sheet_name = this.temp_table_name;
        }
      } else if (tname == "Modifier") {
        if (this.sheet_name == this.temp_modifier_name) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_modifier_data;
          this.sheet_name = this.temp_modifier_name;
        }
      } else if (tname == "Product") {
        if (this.sheet_name == this.temp_product_name) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_product_data;
          this.sheet_name = this.temp_product_name;
        }
      } else if (tname == "Combo") {
        if (this.sheet_name == this.temp_combo_data) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_combo_data;
          this.sheet_name = this.temp_combo_name;
        }
      } else if (tname == "Customer") {
        if (this.sheet_name == this.temp_customer_data) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_customer_data;
          this.sheet_name = this.temp_customer_name;
        }
      } else if (tname == "Inventory") {
        if (this.sheet_name == this.temp_inventory_data) {
          this.do_real_download();
        } else {
          this.sheet_data = this.temp_inventory_data;
          this.sheet_name = this.temp_inventory_name;
        }
      }
    },
    real_export_data(tname, data) {
      if (tname == "Table") {
        if (this.sheet_name == this.sheet_table_name) {
          this.do_real_download();
        } else {
          this.sheet_table_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_table_data;
          this.sheet_name = this.sheet_table_name;
        }
      } else if (tname == "Modifier") {
        if (this.sheet_name == this.sheet_modifier_name) {
          this.do_real_download();
        } else {
          this.sheet_modifier_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_modifier_data;
          this.sheet_name = this.sheet_modifier_name;
        }
      } else if (tname == "Product") {
        if (this.sheet_name == this.sheet_product_name) {
          this.do_real_download();
        } else {
          this.sheet_product_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_product_data;
          this.sheet_name = this.sheet_product_name;
        }
      } else if (tname == "Combo") {
        if (this.sheet_name == this.sheet_combo_name) {
          this.do_real_download();
        } else {
          this.sheet_combo_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_combo_data;
          this.sheet_name = this.sheet_combo_name;
        }
      } else if (tname == "Customer") {
        if (this.sheet_name == this.sheet_customer_name) {
          this.do_real_download();
        } else {
          this.sheet_customer_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_customer_data;
          this.sheet_name = this.sheet_customer_name;
        }
      } else if (tname == "Inventory") {
        if (this.sheet_name == this.sheet_inventory_name) {
          this.do_real_download();
        } else {
          this.sheet_inventory_data = JSON.parse(JSON.stringify(data));
          this.sheet_data = this.sheet_inventory_data;
          this.sheet_name = this.sheet_inventory_name;
        }
      }
    },
    do_export_data(Name) {
      if (!this.$refs || (this.$refs.download_btn == undefined)) {
        // Not ready yet
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("do", Name);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Import/getdata",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.real_export_data(Name, rt.data.exports);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    do_export(row, optype) {
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/Import/" + optype;
      url += "?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&opt=" + this.cur_opt;
      url += "&do=" + row.Name;
      return url;

      // var bodyFormData = new FormData();
      // bodyFormData.append("token", this.token);
      // bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("opt", this.cur_opt);
      // bodyFormData.append("do", row.Name);

      // var that = this;

      // axios({
      //   method: "post",
      //   url: "/Api/Web/Import/" + optype,
      //   data: bodyFormData,
      // })
      //   .then(function (response) {
      //     if (response.status == "200") {
      //       const blob = new Blob([response.data], {
      //         // type: "Content-type: text/csv",
      //         type: "Content-type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //       });
      //       const link = document.createElement("a");
      //       link.href = URL.createObjectURL(blob);
      //       // link.download = row.Name + ".csv";
      //       link.download = row.Name + ".xlsx";
      //       link.click();
      //       URL.revokeObjectURL(link.href);
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     that.$router.push("/login");
      //   });
    },
    do_import(row) {
      // console.log("do_import", row);
      this.currpage = "import";
      this.cur_import = row;
      this.cur_opt = "import";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var r = confirm("Please confirm import");
      if (r != true) {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("do", this.cur_import["Name"]);
      bodyFormData.append("clean", this.clean);
      bodyFormData.append("file1", this.file1);
      this.showloading = true;

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Import/importfile",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              if (that.cur_import["Name"] == "Table") {
                that.$router.push("/table");
              } else if (that.cur_import["Name"] == "Modifier") {
                that.$router.push("/modifier");
              } else if (that.cur_import["Name"] == "Product") {
                let is_retail = getStore("is_retail");
                if (is_retail) {
                  that.$router.push("/product");
                } else {
                  that.$router.push("/item_menu");
                }
              } else if (that.cur_import["Name"] == "Combo") {
                that.$router.push("/modifier");
              } else if (that.cur_import["Name"] == "Customer") {
                that.$router.push("/vip");
              } else if (that.cur_import["Name"] == "Inventory") {
                that.$router.push("/inventory");
              }
              // console.log('200 ok', that.cur_import["Name"] );
              //that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.showloading = false;
          that.$router.push("/login");
        });
    },
    make_one(name, idxArr, notes) {
      var i = 0;
      var j = 0;
      var sheet = [];
      var data = [];
      for (const name in idxArr) {
        data[j++] = idxArr[name];
      }
      sheet[i++] = data;  // Head line
      if (name == "Table") {
        this.sheet_table_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Modifier") {
        this.sheet_modifier_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Product") {
        this.sheet_product_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Combo") {
        this.sheet_combo_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Customer") {
        this.sheet_customer_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Inventory") {
        this.sheet_inventory_data = JSON.parse(JSON.stringify(sheet));
      }

      data = [];
      sheet[i++] = data;    // Empty line

      data = [];
      data[0] = "Following lines are Notes Only. Remove them all when import";
      sheet[i++] = data;    // remark line

      data = [];
      for (j = 0; j < notes.length; j++) {
        data[j] = notes[j];
      }
      sheet[i++] = data;    // remark for each column

      if (name == "Table") {
        this.temp_table_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Modifier") {
        this.temp_modifier_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Product") {
        this.temp_product_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Combo") {
        this.temp_combo_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Customer") {
        this.temp_customer_data = JSON.parse(JSON.stringify(sheet));
      } else if (name == "Inventory") {
        this.temp_inventory_data = JSON.parse(JSON.stringify(sheet));
      }
    },
    make_default() {
      for (const name in this.import_exportlist) {
        this.make_one(this.import_exportlist[name].Name, this.import_exportlist[name].IndexArr, this.import_exportlist[name].Notes);
      }
    },
    async get_import_export_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Import",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.currpage = "list";
              that.import_exportlist = rt.data.imports;
              that.make_default();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_import_export_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.submitbutton {
  margin-top: 2rem;
}
.form-checkbox {
  margin-top: 2.5rem;
}
</style>
